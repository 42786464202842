import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import ContextConsumer from "src/layouts/Context.js";
import colors from "src/assets/styles/colors.js";
import mediaQuery from "src/assets/styles/mediaQuery.js";
import star from "src/assets/svg/star_half.svg";
import typographySizes from "src/assets/styles/typographySizes.js";
import distances from "src/assets/styles/distances.js";
import { StaticQuery, graphql } from "gatsby";
import Icon from "src/assets/svg/copy.inline.svg";
import Close from "src/assets/svg/close.inline.svg";

const Wrapper = styled.div`
  width: 90%;
  max-width: 815rem;
  background-color: ${colors.white};
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  border: solid 3rem ${colors.dark};
  display: none;
  &.open {
    display: block;
  }
`;

const Bg = styled.div`
  z-index: 1;
  height: 100vh;
  width: 100vw;
  background-color: white;
  opacity: 0.9;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  display: none;
  &.open {
    display: block;
  }
`;
const TopWrapper = styled.div`
  position: relative;
  border-bottom: solid 3rem ${colors.dark};
  padding: ${distances.gap}rem;
  padding-top: ${distances.gap / 2}rem;
  padding-bottom: ${distances.gap / 2}rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* @media (max-width: ${mediaQuery.laptop}) {
    padding-bottom: ${(props) => (props.small || props.mid ? 40 : 30)}%;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    padding-bottom: 50%;
  }
  @media (max-width: ${mediaQuery.mobile}) {
    padding-bottom: 70%;
  } */
  ::after {
    content: "";
    position: absolute;
    bottom: -1.5rem;
    right: -3rem;
    background-image: url(${star});
    height: 45rem;
    width: 69rem;
    transform: translateY(50%);
    z-index: 2;
  }
  ::before {
    content: "";
    position: absolute;
    bottom: -1.5rem;
    left: -3rem;
    background-image: url(${star});
    height: 45rem;
    width: 69rem;
    transform: translateY(50%) rotate(180deg);
    z-index: 2;
  }
`;

const Title = styled.h3`
  font-size: ${typographySizes.l}rem;
  font-family: "DM Serif Display", serif;
`;

const PosTitle = styled.h3`
  font-size: 10rem;
  font-weight: bold;
`;

const PosWrapper = styled.div`
  margin-top: ${distances.gap}rem;
`;

const AllWrapper = styled.div`
  margin: 50rem ${distances.gap}rem;
`;

const RowWrapper = styled.div`
  display: flex;
  margin-top: 5rem;
  justify-content: space-between;
`;

const AdresWrapper = styled.div`
  background: #fff5f5;
  font-size: ${typographySizes.s}rem;
  padding: 8rem 20rem;
  width: calc(100% - 100rem);
  overflow: scroll;

  font-family: "Roboto Mono";
`;

const StyledClose = styled(Close)`
  cursor: pointer;
  transition: 0.5s cubic-bezier(0.65, 0, 0.35, 1);

  :hover {
    transform: rotate(180deg);
    transition: 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

const CryptoModal = ({ wallets }) => {
  return (
    <ContextConsumer>
      {({ data, set }) => (
        <>
          <Bg className={data.cryptoModal && "open"} />
          <Wrapper className={data.cryptoModal && "open"}>
            <TopWrapper>
              <Title>Tips</Title>{" "}
              <StyledClose
                onClick={() => {
                  set({ cryptoModal: false });
                }}
              />
            </TopWrapper>
            <AllWrapper>
              {wallets.map((pos) => (
                <PosWrapper>
                  <PosTitle>{pos.Name}</PosTitle>
                  <RowWrapper>
                    <AdresWrapper>{pos.Wallet_addres}</AdresWrapper>{" "}
                    <StyledIcon
                      onClick={() => {
                        navigator.clipboard.writeText(pos.Wallet_addres);
                      }}
                    />
                  </RowWrapper>
                </PosWrapper>
              ))}
            </AllWrapper>
          </Wrapper>
        </>
      )}
    </ContextConsumer>
  );
};

export default CryptoModal;
