import React, { useState } from "react";
import styled, { keyframes } from "styled-components";

import colors from "src/assets/styles/colors.js";

import addToMailchimp from "gatsby-plugin-mailchimp";
import star from "src/assets/svg/star_half.svg";

import distances from "src/assets/styles/distances.js";
import typographySizes from "src/assets/styles/typographySizes.js";
import Paragraph from "src/components/global/Paragraph.js";
import mediaQuery from "src/assets/styles/mediaQuery.js";
import { StaticImage } from "gatsby-plugin-image";

const Title = styled.h3`
  font-size: ${typographySizes.l}rem;
  font-family: "DM Serif Display", serif;
  margin-bottom: 5rem;
`;

const Wrapper = styled.div`
  background-position: center;
  background-size: cover;
  overflow: hidden;
  position: relative;
  border: solid 3rem ${colors.dark};

  @media (max-width: ${mediaQuery.laptop}) {
  }
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: ${distances.gap * 3}rem;
  }
`;

const ImgWrapper = styled.div`
  height: 100%;
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  object-fit: cover absolute !important;
  div {
    height: 100%;
    width: 100%;
  }
`;

const TopWrapper = styled.div`
  padding: ${distances.gap}rem;
  position: relative;
  border-bottom: solid 3rem ${colors.dark};
  padding-top: ${distances.gap / 2}rem;
  ::after {
    content: "";
    position: absolute;
    bottom: -1.5rem;
    right: -3rem;
    background-image: url(${star});
    height: 45rem;
    width: 69rem;
    transform: translateY(50%);
    z-index: 2;
  }
  ::before {
    content: "";
    position: absolute;
    bottom: -1.5rem;
    left: -3rem;
    background-image: url(${star});
    height: 45rem;
    width: 69rem;
    transform: translateY(50%) rotate(180deg);
    z-index: 2;
  }
`;

const BottomWrapper = styled.div`
  padding: ${distances.gap}rem;
`;

const StyledImput = styled.input`
  font-size: ${typographySizes.s}rem;
  border: solid 3rem ${colors.dark};
  background-color: ${colors.white};
  height: 68rem;
  padding-left: 15rem;
  width: calc(100% - 21rem);
  margin-bottom: ${distances.gap}rem;
  &::-webkit-input-placeholder {
    color: ${colors.dark};
    opacity: 0.5;
    font-size: ${typographySizes.s}rem;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: calc(100% - 17rem);
    max-width: initial;
  }
`;

const success = keyframes`
   0%, 100% {
    transform: translateY(0%);
  }
  25% {
    transform: translateY(-100%);
  }
  77% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(-200%);
  }
`;

const Button = styled.button`
  display: inline-block;
  height: 68rem;
  padding: 0 30rem;
  line-height: 68rem;
  text-align: center;
  font-size: ${typographySizes.s}rem;
  width: 100%;
  cursor: pointer;
  color: ${colors.white};
  background-color: ${colors.dark};
  border: solid 3rem ${colors.dark};
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  overflow: hidden;
  :hover {
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    color: ${colors.white};
    background-color: ${colors.darkGreen};
  }
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 10rem;
    margin-left: 0rem;
  }
  span {
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    display: block;
    position: relative;
    top: -2px;
    color: inherit;
    font-weight: 900;
  }
  &.success {
    span {
      animation: ${success} 3s cubic-bezier(0.65, 0, 0.35, 1);
    }
  }
`;

const Shadow = styled.div`
  background-image: linear-gradient(
    to left bottom,
    #d563ff,
    #d688ff,
    #d9a7ff,
    #e0c3ff,
    #eadeff,
    #dbd2ee,
    #ccc7dc,
    #bebbcb,
    #908ba5,
    #645e80,
    #39345d,
    #0d0e3b
  );
  width: calc(100% + 20rem);
  height: calc(100% + 20rem);
  position: absolute;
  left: -10rem;
  top: -10rem;
  z-index: -1;
  filter: blur(10rem);
  opacity: 0.7;
  transition: 0.5s cubic-bezier(0.65, 0, 0.35, 1);
`;

const ButtonWrapper = styled.div`
  position: relative;
  transition: 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  :hover {
    transform: scale(0.99);
    transition: 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    ${Shadow} {
      opacity: 0.4;
      transform: scale(0.99);
      transition: 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }
  }
`;

const Message = styled.div`
  margin-top: 10rem;
  font-size: 10rem;
  color: ${colors.white};
`;
const StyledFieldset = styled.fieldset`
  margin-top: 30rem;
  max-width: 630rem;
  border: none;
`;

const StyledCheckmark = styled.span`
  display: block;
  position: relative;
  top: -1px;
  width: 40rem;
  height: 40rem;
  margin: -1px 0px 0 0;
  vertical-align: middle;
  margin-right: 15rem;
  cursor: pointer;
  border: solid 3rem ${colors.dark};
  background-color: ${colors.white};
  flex-shrink: 0;
  ::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 0rem;
    width: 0rem;
    background-color: ${colors.dark};
    transition: 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
`;

const LabelWrapper = styled.label`
  display: flex;
  .gdpr {
    display: none;
  }
  .gdpr:checked + ${StyledCheckmark} {
    ::before {
      height: 30rem;
      width: 30rem;
      transition: 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }
  }
`;

const GdprText = styled.span`
  font-size: 10rem;
  color: ${colors.white};
  max-width: 640rem; ;
`;

const SideSubscribe = () => {
  const [email, setEmail] = useState("");

  const [result, setResult] = useState("");
  const [msg, setMsg] = useState(null);
  const [isChecked, setisChecked] = useState(false);
  const [success, setSuccess] = useState(false);

  const _handleSubmit = async (e) => {
    e.preventDefault();
    if (isChecked) {
      const result = await addToMailchimp(email, { "gdpr[191629]": "Y" });
      setResult(result);

      setMsg(result.msg);
      if (result.result === "success") {
        setSuccess(true);
        setInterval(function () {
          setSuccess(false);
        }, 3100);
      }
    } else {
      setMsg("Wyrażenie zgody jest wymagane");
    }
  };
  const handleChange = (event) => {
    setEmail(event.target.value);
  };
  const handleChangeGdpr = (event) => {
    const premision = {
      marketing_permissions: [
        { marketing_permission_id: "gdpr[191629]", enabled: true }, //
      ],
    };
  };

  const selectShortlistedApplicant = (e) => {
    if (!isChecked) {
      setisChecked(true);
    } else {
      setisChecked(false);
    }
  };
  return (
    <Wrapper>
      <ImgWrapper>
        <StaticImage
          className="img"
          objectFit="cover"
          src="../../assets/images/side-sub-bg.png"
          placeholder="blurred"
          alt="zdjęcie w tle"
        />
      </ImgWrapper>
      <TopWrapper>
        <Title>Subscribe</Title>
        <Paragraph>Your weekly updates inside the Inbox</Paragraph>
      </TopWrapper>
      <BottomWrapper>
        <form onSubmit={_handleSubmit}>
          <StyledImput
            id="outlined-email-input"
            placeholder="Podaj email"
            type="email"
            name="email"
            autoComplete="email"
            variant="outlined"
            onChange={handleChange}
          />
          <ButtonWrapper>
            <Shadow />

            <Button
              type="submit"
              value="Subscribe"
              name="subscribe"
              id="mc-embedded-subscribe"
              className={success ? "success" : ""}
            >
              <span>Zapisz się</span>
              <span>Dziękujemy</span>
              <span>Zapisz się</span>
            </Button>
          </ButtonWrapper>
          <StyledFieldset
            name="interestgroup_field"
            className="classFieldset mc_fieldset gdprRequired mc-field-group"
          >
            <LabelWrapper
              for="gdpr_191629"
              onClick={(e) => {
                selectShortlistedApplicant(e);
              }}
            >
              <input
                type="checkbox"
                id="gdpr_191629 acceptTerms"
                name="gdpr[191629]"
                value="Y"
                className="av-checkbox gdpr"
                type="checkbox"
                onChange={handleChangeGdpr}
                checked={isChecked}
              />
              <StyledCheckmark />
              <GdprText>
                Wyrażam zgodę na przetwarzanie moich danych osobowych w celu
                prowadzenia przez xxx s.c. z siedzibą w Warszawie marketingu
                bezpośredniego.
                {result.msg ? <Message> {msg}</Message> : <Message> </Message>}
                {!isChecked ? <Message> {msg}</Message> : <Message> </Message>}
              </GdprText>
            </LabelWrapper>
          </StyledFieldset>
        </form>
      </BottomWrapper>
    </Wrapper>
  );
};

export default SideSubscribe;
