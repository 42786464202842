import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";

import colors from "src/assets/styles/colors.js";

import star from "src/assets/svg/star_half.svg";
import ContextConsumer from "src/layouts/Context.js";

import distances from "src/assets/styles/distances.js";
import typographySizes from "src/assets/styles/typographySizes.js";
import Paragraph from "src/components/global/Paragraph.js";
import mediaQuery from "src/assets/styles/mediaQuery.js";

import ethPrice from "eth-price";

import Units from "ethereumjs-units";

const Title = styled.h3`
  font-size: ${typographySizes.l}rem;
  font-family: "DM Serif Display", serif;
  margin-bottom: 5rem;
`;

const Wrapper = styled.div`
  background-position: center;
  background-size: cover;
  overflow: hidden;
  margin-top: ${distances.gap}rem;
  border: solid 3rem ${colors.dark};
  position: relative;
  background-color: ${colors.white};
  @media (max-width: ${mediaQuery.laptop}) {
  }
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: ${distances.gap * 3}rem;
  }
`;

const TopWrapper = styled.div`
  padding: ${distances.gap}rem;
  padding-top: ${distances.gap / 2}rem;
  position: relative;
  border-bottom: solid 3rem ${colors.dark};
  ::after {
    content: "";
    position: absolute;
    bottom: -1.5rem;
    right: -3rem;
    background-image: url(${star});
    height: 45rem;
    width: 69rem;
    transform: translateY(50%);
    z-index: 2;
  }
  ::before {
    content: "";
    position: absolute;
    bottom: -1.5rem;
    left: -3rem;
    background-image: url(${star});
    height: 45rem;
    width: 69rem;
    transform: translateY(50%) rotate(180deg);
    z-index: 2;
  }
`;

const StyledParagraph = styled(Paragraph)`
  max-width: 250rem;
`;

const BottomWrapper = styled.div`
  padding: ${distances.gap}rem;
`;
const InputWrapper = styled.div`
  background-color: red;
  position: relative;
  margin-bottom: ${distances.gap}rem;

  ::after {
    content: "ETH";
    position: absolute;
    right: 20rem;
    top: 50%;
    transform: translateY(-50%);
    font-size: ${typographySizes.s}rem;
  }
`;
const PriceDolar = styled.span`
  position: absolute;
  top: 10rem;
  right: 20rem;
  font-size: 10rem;
`;

const StyledImput = styled.input`
  font-size: ${typographySizes.s}rem;
  border: solid 3rem ${colors.dark};
  background-color: ${colors.white};
  height: 68rem;
  padding-left: 15rem;
  width: calc(100% - 21rem);
  &::-webkit-input-placeholder {
    color: ${colors.dark};
    opacity: 0.5;
    font-size: ${typographySizes.s}rem;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: calc(100% - 17rem);
    max-width: initial;
  }
`;

const success = keyframes`
   0%, 100% {
    transform: translateY(0%);
  }
  25% {
    transform: translateY(-100%);
  }
  77% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(-200%);
  }
`;

const Button = styled.button`
  display: inline-block;
  height: 68rem;
  padding: 0 30rem;
  line-height: 68rem;
  text-align: center;
  font-size: ${typographySizes.s}rem;
  width: 100%;
  cursor: pointer;
  color: ${colors.white};
  background-color: ${colors.dark};
  border: solid 3rem ${colors.dark};
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  overflow: hidden;
  :hover {
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    color: ${colors.white};
    background-color: ${colors.darkGreen};
  }
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 10rem;
    margin-left: 0rem;
  }
  span {
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    display: block;
    position: relative;
    top: -2px;
    color: inherit;
    font-weight: 900;
  }
  &.success {
    span {
      animation: ${success} 3s cubic-bezier(0.65, 0, 0.35, 1);
    }
  }
`;

const ButtonWrapper = styled.div`
  position: relative;
  transition: 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  :hover {
    transform: scale(0.99);
    transition: 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
  button:disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }
`;

const AnotherForm = styled(Paragraph)`
  margin-top: ${distances.gap}rem;
  span {
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }
`;

const SideTip = ({ wallet }) => {
  const [success, setSuccess] = useState(false);
  const [amount, setAmount] = useState(0.01);
  const [ethCurPrice, setEthCurPrice] = useState(1);
  const [metamaskOff, setMetamaskOff] = useState(true);
  const [modalOpen, setModalOpen] = useState(true);
  const isBrowser = typeof window !== "undefined";

  const _handleSubmit = async (e) => {
    e.preventDefault();
    if (isBrowser) {
      send(amount);
      setSuccess(true);
      setInterval(function () {
        setSuccess(false);
      }, 3100);
    }
  };
  const handleChange = (event) => {
    if (isBrowser) {
      setAmount(event.target.value);
    }
  };
  const send = async (amount) => {
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });

    const wei = parseInt(Units.convert(amount, "eth", "wei"));

    const hex = decimalToHexString(wei);

    if (accounts.length > 0) {
      window.ethereum.request({
        method: "eth_sendTransaction",
        params: [
          {
            from: accounts[0],
            to: wallet,
            value: hex,
          },
        ],
      });
    }
  };

  function decimalToHexString(number) {
    if (number < 0) {
      number = 0xffffffff + number + 1;
    }

    return number.toString(16).toUpperCase();
  }
  useEffect(() => {
    ethPrice("usd").then((data) => {
      setEthCurPrice(parseInt(data[0].split(":")[1]));
    });
    if (isBrowser) {
      if (window.ethereum) {
        setMetamaskOff(false);
      }
    }
  }, []);

  return (
    <Wrapper>
      <TopWrapper>
        <Title>Tips</Title>

        <StyledParagraph>
          Jeżeli podoba Ci się nasza praca. Rozważ wsparcie deep space dao
        </StyledParagraph>
      </TopWrapper>
      <BottomWrapper>
        <form onSubmit={_handleSubmit}>
          <InputWrapper>
            <PriceDolar>
              ≈ ${Math.round(amount * ethCurPrice * 100) / 100}
            </PriceDolar>
            <StyledImput
              id="outlined-email-input"
              placeholder="0.01"
              min="0"
              step="0.001"
              type="number"
              name="ethA"
              variant="outlined"
              onChange={handleChange}
            />
          </InputWrapper>
          <ButtonWrapper>
            <Button
              type="submit"
              value="Subscribe"
              name="subscribe"
              id="mc-embedded-subscribe"
              className={success ? "success" : ""}
              disabled={metamaskOff}
            >
              <span>{metamaskOff ? "Zainstaluj metamask" : "Wyślij tipa"}</span>
              <span>Dziękujemy</span>
              <span>Wyślij tipa</span>
            </Button>
          </ButtonWrapper>
        </form>
        <ContextConsumer>
          {({ data, set }) => (
            <AnotherForm>
              Inne możliwośći wsparcia{" "}
              <span
                onClick={() => {
                  set({ cryptoModal: true });
                }}
              >
                Tutaj
              </span>
            </AnotherForm>
          )}
        </ContextConsumer>
      </BottomWrapper>
    </Wrapper>
  );
};

export default SideTip;
