import * as React from "react";
import styled from "styled-components";
import MainWrapper from "src/components/global/MainWrapper.js";
import logo from "src/assets/svg/ds_dao_sygnet_white.svg";
import distances from "src/assets/styles/distances.js";
import typographySizes from "src/assets/styles/typographySizes.js";
import colors from "src/assets/styles/colors.js";
import mediaQuery from "src/assets/styles/mediaQuery.js";

import { StaticImage } from "gatsby-plugin-image";

const Wrapper = styled.header`
  padding: ${distances.gap}rem 0;
  position: relative;

  .img {
    height: 100%;
  }
`;

const ImgWrapper = styled.div`
  height: 100%;
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100vw;
`;

const Logo = styled.img`
  width: 100rem;
  margin-bottom: 150rem;
`;

const BottomWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  @media (max-width: ${mediaQuery.desktop}) {
    flex-direction: column;
  }
  @media (max-width: ${mediaQuery.laptop}) {
    align-items: flex-start;
    flex-direction: column-reverse;
  }
`;

const HeaderIntro = styled.h2`
  font-size: ${typographySizes.s}rem;
  font-family: "Open Sans", sans-serif;
  line-height: 1.5;
  font-weight: 400;
  color: ${colors.white};
  max-width: 330rem;
  @media (max-width: ${mediaQuery.desktop}) {
    margin-bottom: 30rem;
  }
  @media (max-width: ${mediaQuery.laptop}) {
    margin-bottom: 0rem;
    margin-top: 10rem;
  }
`;

const HeaderTitle = styled.h1`
  font-size: 210rem;
  color: ${colors.white};
  font-family: "Urbanist", sans-serif;
  font-weight: 400;
  line-height: 160rem;
  span {
    font-family: "DM Serif Display", serif;
    font-size: inherit;
    color: inherit;
  }
  @media (max-width: ${mediaQuery.laptop}) {
    font-size: 150rem;
    line-height: 1;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: 100rem;
  }
  @media (max-width: ${mediaQuery.mobile}) {
    font-size: 20vw;
  }
`;

const Header = ({ description }) => (
  <Wrapper>
    <ImgWrapper>
      <StaticImage
        className="img"
        objectFit="cover"
        src="../../../assets/images/Header_bg.png"
        placeholder="blurred"
        alt="zdjęcie w tle"
      />
    </ImgWrapper>

    <MainWrapper>
      <Logo src={logo} alt="ds dao sygnet" />
      <BottomWrapper>
        <HeaderIntro>{description}</HeaderIntro>
        <HeaderTitle>
          <span>ds_</span>zine
        </HeaderTitle>
      </BottomWrapper>
    </MainWrapper>
  </Wrapper>
);

export default Header;
