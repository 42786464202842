import * as React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

import Header from "src/components/home/Header/Header.js";
// import P5Wrapper from "src/components/p5js/p5-wrapper.js";
// import Sketch from "src/components/p5js/Sketch.js";
import Sketch from "src/components/SketchF.js";
import SectionTitle from "src/components/global/SectionTitle.js";
import MainWrapper from "src/components/global/MainWrapper.js";
import ArticleCard from "src/components/global/ArticleCard/ArticleCard.js";
import SideSubscribe from "src/components/global/SideSubscribe.js";
import distances from "src/assets/styles/distances.js";
import mediaQuery from "src/assets/styles/mediaQuery.js";
import Seo from "src/components/global/Seo.js";
import SideTip from "src/components/global/SideTip.js";
import CryptoModal from "src/components/global/CryptoModal.js";

const ContentWrapper = styled.div`
  position: relative;
  height: 100%;
`;

const P5 = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;

  div {
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }
`;

const StyledSectionTitle = styled(SectionTitle)`
  margin-top: 90rem;
  margin-bottom: ${distances.gap}rem;
`;

const MidSection = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-top: ${distances.gap}rem;
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
  }
`;

const FeaturedSection = styled.div`
  width: calc(66.66% - 15rem);
  @media (max-width: ${mediaQuery.laptop}) {
    width: calc(50% - 15rem);
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`;

const LatestWrapper = styled.div`
  display: flex;

  flex-wrap: wrap;
  margin-bottom: ${distances.gap * 4}rem;
`;

const Sidecol = styled.div`
  position: sticky;
  align-self: flex-start; /* <-- this is the fix */
  width: calc(33.33% - 21rem);
  top: 30rem;
  @media (max-width: ${mediaQuery.laptop}) {
    width: calc(50% - 21rem);
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: calc(100% - 6rem);
  }
`;

const IndexPage = ({ data }) => {
  return (
    <main>
      <Seo title="ds_zine" description={data.strapiHome.Description} />
      <CryptoModal wallets={data.strapiGeneral.Wallet_addres} />
      <Header description={data.strapiHome.Description} />
      <ContentWrapper>
        <P5>
          <Sketch />
        </P5>

        <MainWrapper>
          <StyledSectionTitle>Polecane</StyledSectionTitle>
          <ArticleCard article={data.strapiFeaturedTop.article} top />

          <MidSection>
            <FeaturedSection>
              {data.featured_section.edges.map((article) => (
                <ArticleCard article={article.node} mid />
              ))}
            </FeaturedSection>
            <Sidecol>
              <SideSubscribe />
              <SideTip wallet={data.strapiGeneral.Adres_ethereum} />
            </Sidecol>
          </MidSection>
          <StyledSectionTitle>Ostatnie</StyledSectionTitle>

          <LatestWrapper>
            {" "}
            {data.allStrapiArticle.edges.map((article) => (
              <ArticleCard article={article.node} small />
            ))}
          </LatestWrapper>
        </MainWrapper>
      </ContentWrapper>
    </main>
  );
};

export default IndexPage;

export const IndexQuery = graphql`
  query Home {
    strapiGeneral {
      Adres_ethereum
      Wallet_addres {
        Name
        Wallet_addres
      }
    }
    strapiHome {
      Description
    }
    strapiFeaturedTop {
      article {
        Title
        Twitter_badge
        Subtitle
        publishedAt(formatString: "DD.MM.YY")
        level {
          Level
        }
        Content {
          ... on STRAPI__COMPONENT_ARTICLE_TEXT {
            Text {
              data {
                Text
              }
            }
          }
        }
        Main_img {
          alternativeText
          localFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        author {
          Name
          Avatar {
            alternativeText
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        tags {
          Name
        }
      }
    }
    featured_section: allStrapiArticle(
      sort: { order: DESC, fields: publishedAt }
      filter: {
        Title: { ne: "Sample" }
        Featured_section: { eq: true }
        featured_top: { Featured_top: { ne: "Yes" } }
      }
    ) {
      edges {
        node {
          Title
          Twitter_badge
          Subtitle
          publishedAt(formatString: "DD.MM.YY")
          level {
            Level
          }
          Content {
            ... on STRAPI__COMPONENT_ARTICLE_TEXT {
              Text {
                data {
                  Text
                }
              }
            }
          }
          Main_img {
            alternativeText
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          author {
            Name
            Avatar {
              alternativeText
              localFile {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          tags {
            Name
          }
        }
      }
    }
    allStrapiArticle(
      sort: { order: DESC, fields: publishedAt }
      filter: {
        Title: { ne: "Sample" }
        Featured_section: { ne: true }
        featured_top: { Featured_top: { ne: "Yes" } }
      }
    ) {
      edges {
        node {
          Title
          Twitter_badge
          Subtitle
          publishedAt(formatString: "DD.MM.YY")
          level {
            Level
          }
          Content {
            ... on STRAPI__COMPONENT_ARTICLE_TEXT {
              Text {
                data {
                  Text
                }
              }
            }
          }
          Main_img {
            alternativeText
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          author {
            Name
            Avatar {
              alternativeText
              localFile {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          tags {
            Name
          }
        }
      }
    }
  }
`;
